const utmParams = [
  'utm_source',
  'utm_content',
  'utm_campaign',
  'placement',
  'utm_term',
  'adset',
  'utm_medium',
]

const extractUrlParams = (paramNames) => {
  const urlParams = new URLSearchParams(window.location.search)
  return paramNames.reduce((acc, paramName) => {
    return {
      ...acc,
      [paramName]: urlParams.get(paramName) || undefined,
    }
  }, {})
}

const sendApplication = async (payload) => {
  return await fetch('https://api.csssr.com/submit-form', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: payload.email,
      company: payload.company,
      message: payload.message,
      firstName: payload.firstName,
      pageName: 'contactUs',
      fbc: getCookie('_fbc'),
      fpb: getCookie('_fbp'),
      utm: extractUrlParams(utmParams),
    }),
  })
}

function getCookie(cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookies = decodedCookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i]
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }
  return undefined
}

const formContainer = document.getElementById('formContainer')
const applicationForm = document.getElementById('applicationForm')
const submitButton = document.querySelector(
  '#applicationForm .applicationForm__submitButton'
)
const emailField = document.getElementById(
  'email-f5e363ee-b10b-43d8-a987-a7f7dd45be29'
)
const companyField = document.getElementById('applicationFormCompany')
const nameField = document.getElementById('applicationFormName')
const errorSubmit = document.getElementById('errorSubmit')

const errorSubmitMessage = "Couldn't send. Please contact us by email or phone"

const getErrorMessage = (field) => {
  let message = null
  const { valueMissing, typeMismatch } = field.validity

  if (valueMissing) {
    message = 'Fill out the field to send a message'
  }

  if (typeMismatch) {
    message = 'Invalid email address'
  }

  return message
}

const setErrorState = (field, message) => {
  field.setCustomValidity(' ')

  field.classList.add('input__type_invalid')
  field.nextSibling.innerText = message
}

const resetErrorState = (field) => {
  if (field.classList.contains('input__type_invalid')) {
    field.setCustomValidity('')

    field.classList.remove('input__type_invalid')
    field.nextSibling.innerText = ''
  }
}

const handleErrorState = (evt) => {
  evt.preventDefault()

  setErrorState(evt.target, getErrorMessage(evt.target))
}

emailField.addEventListener('input', ({ target }) => resetErrorState(target))

emailField.addEventListener('invalid', handleErrorState)

companyField.addEventListener('input', ({ target }) => resetErrorState(target))

companyField.addEventListener('invalid', handleErrorState)

nameField.addEventListener('input', ({ target }) => resetErrorState(target))

nameField.addEventListener('invalid', handleErrorState)

applicationForm.addEventListener('submit', async (evt) => {
  try {
    evt.preventDefault()

    const form = evt.target

    const { email, company, message, firstName } = form.elements

    submitButton.setAttribute('disabled', 'disabled')

    const response = await sendApplication({
      email: email.value.trim(),
      company: company.value.trim(),
      message: message.value.trim() ?? undefined,
      firstName: firstName.value.trim(),
    })

    if (response?.status !== 201) {
      errorSubmit.innerText = errorSubmitMessage

      return
    }

    errorSubmit.innerText = ''
    formContainer.classList.add('applicationForm--state-success')
  } catch (err) {
    console.error(err)
  } finally {
    submitButton.removeAttribute('disabled')
  }
})
